import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Card from '../components/card'
import ColumnAlternating from '../components/columnAlternatingStyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'

const ResourcesTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />

        {post.html && (
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                <div className="font-w-400 text-xs-extra-large divlink text-opening-para" dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }} />
                <div className="text-medium mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.section && (
          <>
            {post.frontmatter.section.map((sections, index) => (
              <Fragment key={index}>
                {sections.placement === 'cards' && (
                  <>
                    {sections.feature.filter( (resource) => resource.featured === true ).length > 0 && (
                      <section id="featured" className="bg-light-blue">
                        <MDBContainer>
                              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5"> Featured {post.frontmatter.name.toLowerCase()} </h2>

                          <MDBRow>
                            {sections.feature.filter((resource) => resource.featured === true).length === 1 ? (
                              <>
                                {sections.feature.filter((resource) => resource.featured === true).map((features) => (
                                    <ColumnAlternating
                                      key={1}
                                      textCol={'7'}
                                      imageCol={'5'}
                                      title={features.title}
                                      subtitle={features.subtitle}
                                      subtitlecolour="text-very-dark-blue"
                                      description={features.description}
                                      image={ features.image.childImageSharp.gatsbyImageData }
                                      alt={features.alttext}
                                      placement="left"
                                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                                      link={features.link ? features.link : features.document.publicURL}
                                      colour="ficon-iet"
                                    />
                                  ))}
                              </>
                            ) : (
                              <>
                                {sections.feature.filter((resource) => resource.featured === true).map((features, index) => (
                                    <Card
                                      key={index}
                                      collg="4"
                                      colmd="6"
                                      height="7.5rem"
                                      title={features.title}
                                      subtitle={features.subtitle}
                                      description={features.description}
                                      image={ features.image ? features.image.childImageSharp.gatsbyImageData : null }
                                      alttext={features.alttext}
                                      link={features.link ? features.link : features.document.publicURL}
                                      titleclass="text-medium"
                                      descriptionClass="text-card-small"
                                    />
                                  ))}
                              </>
                            )}
                          </MDBRow>
                        </MDBContainer>
                      </section>
                    )}

                    <section className={ sections.feature.filter((resource) => resource.featured === true).length > 0 ? 'bg-white' : 'bg-light-blue' } >
                      <MDBContainer>
                        <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5"> {sections.title} </h2>
                        <MDBRow>
                          {sections.feature.filter((resource) => resource.featured === false).map((features, index) => (
                              <Card
                                key={index}
                                collg="4"
                                colmd="6"
                                height="9rem"
                                title={features.title}
                                subtitle={features.subtitle}
                                description={features.description}
                                image={ features.image ? features.image.childImageSharp.gatsbyImageData : null }
                                alttext={features.alttext}
                                link={features.link ? features.link : features.document.publicURL}
                                titleclass="text-medium"
                                descriptionClass="text-card-small"
                              />
                            ))}
                        </MDBRow>
                      </MDBContainer>
                    </section>
                  </>
                )}

                {sections.placement === 'list' && (
                  <section className={index % 2 ? 'bg-white' : 'bg-light-blue'}>
                    <MDBContainer>
                      <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-5"> {sections.title} </h2>
                      {sections.feature.map((features, index) => (
                        <MDBRow className="px-3" key={index}>
                          <MDBCol lg="12" md="12">
                            <MDBRow style={{ minHeight: features.subtitle ? '8rem' : '4rem', marginBottom: '5rem', borderLeft: '6px solid #EE7110', }} className="bg-white rounded innovation-box p-4" >
                              <MDBCol lg="9" md="7" className="my-auto">
                                <h3 className="font-w-600 text-extra-large mb-0"> {features.title} </h3>
                                {features.subtitle && (
                                  <p className="font-w-400 text-medium mb-0"> {features.subtitle} </p>
                                )}
                                {features.date && (
                                <p className="font-alt font-w-400 text-medium-small mt-2 mb-0"> {features.date} </p>
                                )}
                              </MDBCol>
                              <MDBCol lg="3" md="5" className="my-auto">
                                {features.link && (
                                  <>
                                    <a href={features.link} className="nav-link btn btn-mdb-color btn-sm-block float-md-right mt-3 mt-md-0" target="_blank" rel="noopener" > <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-2" />View article </a>
                                  </>
                                )}
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      ))}
                    </MDBContainer>
                  </section>
                )}
              </Fragment>
            ))}
          </>
        )}
      </Layout>
    </div>
  )
}
export default ResourcesTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "resources" } }
    ) {
      frontmatter {
        meta {
          title
          description
        }
        name
        title
        subtitle
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        alttext
        section {
          title
          subtitle         
          placement
          feature {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
            linktext
            link
            document {
              publicURL
            }
            featured
          }
        }
      }
      html
    }
  }
`
